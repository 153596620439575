import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const SavingCalculator = ({ViewedPage, shop_domain, app_url}) => {
  ViewedPage(22) 

  const [googleAdsSpend, setGoogleAdsSpend] = useState("30000");
  const [conversionRateLift, setConversionRateLift] = useState("10");
  const [scaleAISubscriptionCost, setScaleAISubscriptionCost] = useState("399");
  const [savings, setSavings] = useState(0);
  const [roi, setRoi] = useState(0);

  const calculateSavingsAndROI = () => {
    const googleAdsSpendValue = parseFloat(googleAdsSpend || 0);
    const conversionRateLiftValue = parseFloat(conversionRateLift || 0) / 100; 
    const scaleAISubscriptionCostValue = parseFloat(scaleAISubscriptionCost || 0);

    // Calculate Revenue Lift
    const revenueLift = googleAdsSpendValue * conversionRateLiftValue;

    // Calculate Savings
    const calculatedSavings = revenueLift - scaleAISubscriptionCostValue;
    setSavings(calculatedSavings);

    // Calculate ROI (%)
    const calculatedROI = (revenueLift * 100) / scaleAISubscriptionCostValue;
    setRoi(calculatedROI);
  };

  // Handle input changes while preserving string format for better editing
  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;
    // Allow empty string or valid number string
    if (value === '' || !isNaN(value)) {
      setter(value);
    }
  };

  // Styles matching GroupsSettings component
  const styles = {
    mainContainer: {
      position: "relative",
      padding: "30px", 
      backgroundColor: "#fff",
      borderRadius: "8px",
      margin: "25px auto", 
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      width: "100%",
      maxWidth: "100%"
    },
    title: {
      fontSize: "24px",
      fontWeight: "700",
      marginBottom: "35px", 
      color: "#6c757d"
    },
    sectionHeading: {
      fontSize: "20px", 
      fontWeight: "600", 
      color: "#6c757d"
    },
    subSectionHeading: {
      fontSize: "18px", 
      fontWeight: "600", 
      color: "#6c757d"
    },
    inputLabel: {
      fontSize: "14px",
      fontWeight: "500",
      marginBottom: "8px",
      color: "#4a5568"
    },
    input: {
      width: "100%",
      padding: "10px 14px", 
      border: "1px solid #e2e8f0",
      borderRadius: "6px",
      fontSize: "14px",
      color: "#4a5568",
      "&:focus": {
        borderColor: "#4299e1",
        boxShadow: "0 0 0 3px rgba(66, 153, 225, 0.5)"
      }
    },
    helperText: {
      fontSize: "12px",
      color: "#718096",
      marginTop: "4px"
    },
    resultsPanel: {
      backgroundColor: "#2b4570", 
      padding: "28px",
      borderRadius: "8px",
      border: "1px solid #e2e8f0",
      height: "100%",
      color: "#fff" 
    },
    savingsAmount: {
      fontSize: "32px",
      fontWeight: "600",
      color: "#ffffff",
      margin: "16px 0"
    },
    divider: {
      borderTop: "1px solid rgba(255,255,255,0.2)", 
      margin: "28px 0" 
    }
  };

  return (
    <div className="main-container" style={{ position: "relative", padding: "25px", width: "100%" }}>
      <Container fluid style={{ maxWidth: "100%" }}>
        <Row className="justify-content-center" style={{ display: "flex", alignItems: "stretch" }}> {/* Ensure equal height */}
          <Col xl={12} lg={12} style={{ padding: 0 }}>
            <div style={styles.mainContainer}>
              {/* Updated Title to match Groups styling */}
              <h2 style={styles.title}>ScaleX AI Savings Calculator</h2>
              
              <Row style={{ display: "flex", alignItems: "stretch" }}> {/* Ensure equal height */}
                {/* Left Column - Inputs */}
                <Col lg={6} className="pe-lg-5" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}> {/* Flexbox for equal height */}
                  <div>
                    <Form.Group className="mb-4">
                      <Form.Label style={styles.inputLabel}>
                        Google Ads Monthly Spend ($)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={googleAdsSpend}
                        onChange={handleInputChange(setGoogleAdsSpend)}
                        style={styles.input}
                        placeholder="0"
                      />
                      <Form.Text style={styles.helperText}>
                        Enter your monthly Google Ads spend.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label style={styles.inputLabel}>
                        Conversion Rate Lift (%)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={conversionRateLift}
                        onChange={handleInputChange(setConversionRateLift)}
                        style={styles.input}
                        placeholder="0"
                      />
                      <Form.Text style={styles.helperText}>
                        Enter the conversion rate lift in percentage (e.g., 20 for 20%).
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-5"> {/* Increased bottom margin slightly */}
                      <Form.Label style={styles.inputLabel}>
                        ScaleX AI Monthly Subscription Cost ($)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={scaleAISubscriptionCost}
                        onChange={handleInputChange(setScaleAISubscriptionCost)}
                        style={styles.input}
                        placeholder="0"
                      />
                      <Form.Text style={styles.helperText}>
                        Enter the monthly cost of ScaleX AI service.
                      </Form.Text>
                    </Form.Group>
                  </div>

                  <Button 
                    onClick={calculateSavingsAndROI}
                    variant="primary"
                    style={{
                      width: "100%",
                      padding: "12px",
                      fontWeight: "500",
                      fontSize: "16px",
                      backgroundColor: "#2b4570",
                      border: "none"
                    }}
                  >
                    Calculate Savings
                  </Button>
                </Col>

                {/* Right Column - Results */}
                <Col lg={6} className="ps-lg-5 mt-4 mt-lg-0" style={{ display: "flex", flexDirection: "column" }}> {/* Flexbox for equal height */}
                  <div style={{ ...styles.resultsPanel, flex: 1 }}> {/* Ensure it stretches to full height */}
                    <h4 style={{...styles.sectionHeading, color: "#ffffff"}}>
                    Monthly Savings
                    </h4>
                    <p style={{...styles.helperText, color: "rgba(255,255,255,0.8)"}}>Your calculated savings amount</p>
                    <div style={styles.savingsAmount}>
                      ${savings.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                    </div>

                    <div style={styles.divider}></div>

                    <h4 style={{...styles.subSectionHeading, color: "#ffffff"}}>
                    Return on Investment
                    </h4>
                    <p style={{...styles.helperText, color: "rgba(255,255,255,0.8)"}}>ROI from ScaleX AI</p>
                    <div style={{ ...styles.savingsAmount, fontSize: "24px" }}>
                      {roi.toFixed(2)}%
                    </div>

                    <div style={styles.divider}></div>

                    <p style={{ color: "rgba(255,255,255,0.8)", margin: "20px 0" }}>
                      Ready to boost your marketing ROI?<br />
                      Use ScaleX AI to improve your ad performance.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SavingCalculator;