import React, { useEffect, useState } from 'react'
import scalex_ai_logo from "../images/scalex_ai_logo.png"
import google_logo from "../images/google.png"
import meta_logo from "../images/meta.png"
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form"
import axios from 'axios';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

function Sidebar({app_url, ViewedPage, openSidebarToggle, OpenSidebar, selectedPage, stores, changeStoreDomain, changeStoreName, allowed_pages_json, shop_domain, ShopName, show_under_dev_pages}) {
    const [allowed_pages_for_store, setAllowedPages] = useState([4,5]);
    const [current_shop, setCurrentShop] = useState(Cookies.get('shop'));
    const navigate = useNavigate();

    useEffect(() => {
        if(allowed_pages_json && Object.keys(allowed_pages_json).includes(current_shop.replace(".myshopify.com","")) && allowed_pages_json[current_shop.replace(".myshopify.com","")] && Object.values(allowed_pages_json[current_shop.replace(".myshopify.com","")])){
            console.log("Allowewd Pages Fetched Some cookies")
            setAllowedPages(Object.values(allowed_pages_json[current_shop.replace(".myshopify.com","")]))
        }
        else{
            setAllowedPages([4,5])
        }
    }, [current_shop, allowed_pages_json])

  return (
    <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive": ""}>
        <div className='sidebar-title' id='sidebar-title-responsive'>
            <div className='sidebar-brand'>
                <img src={scalex_ai_logo} alt='logo' width={40} className='icon_header'/> ScaleX AI
            </div>
        </div>
        <div className='sidebar-title' id='top-bottom-border'>
            <div className='sidebar-customer'>
                {/* {ShopName} */}
                <Form.Select defaultValue={current_shop.replace(".myshopify.com","")} onChange={(e)=>{
                    e.preventDefault()
                    axios.post(app_url+"/change_current_shop",{
                        new_shop_domain:e.target.value,
                    }, {withCredentials:true})
                    .then(res => res.data)
                    .then(data => {
                        changeStoreDomain(e.target.value) 
                        setCurrentShop(e.target.value)
                        navigate("/")
                        console.log("In sidebar",e.target.value)
                    })
                    .catch(err => {
                        toast.error("Please try after some time.");
                        console.log(err);
                    })
                }}>
                    {stores.map((store_iter)=>{
                        return (<option value={store_iter["value"].replace(".myshopify.com","")} key={store_iter["label"]}>{store_iter["label"]}</option>)
                    })}
                </Form.Select>
            </div>
            <span className='icon close_icon' onClick={OpenSidebar}>X</span>
        </div>

        <ul className='sidebar-list'>

            <li style={{display:`${((allowed_pages_for_store.filter(item => [10,15,16,20,14,18].includes(item))).length > 0) ? 'block': 'none'}`}} className={[10,15,16,20,14,18].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'} >
                <span className='sidebar-list-hover-title' onClick={()=>{
                    let display_style = document.getElementById("sidebar-product").style.display;
                    if(display_style === "none"){
                        document.getElementById("sidebar-product-down").style.display = "none";
                        document.getElementById("sidebar-product-up").style.display = "block";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    else{
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    
                    document.getElementById("sidebar-performance").style.display = "none";
                    document.getElementById("sidebar-integration").style.display = "none";
                    document.getElementById("sidebar-website-optimization").style.display = "none";
                    document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
                    document.getElementById("sidebar-customer-behavior").style.display = "none";
                    document.getElementById("sidebar-product").style.display = display_style === "block" ? "none": "block";
                }}>
                    <p style={{margin:"0"}}>Communication AI</p>
                    <svg id='sidebar-product-up' style={{display:`${[10,15,16,20,14,18].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                    </svg>
                    <svg id='sidebar-product-down' style={{display:`${[10,15,16,20,14,18].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg>
                </span>
                <ul className='sidebar-list-dropdown' id='sidebar-product'>
                    <li style={{display:`${allowed_pages_for_store.includes(20) ? 'block': 'none'}`}} className={selectedPage === 20 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/chatbot">Contextual Ideation</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(10) ? 'block': 'none'}`}} className={selectedPage === 10 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/ads-copy-optimization">Ad Copy Optimizations</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(15) ? 'block': 'none'}`}} className={selectedPage === 15 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/google-ads-copy-ss">Ad Copies Generation (New Adgroups)</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(16) ? 'block': 'none'}`}} className={selectedPage === 16 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={meta_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/creative-analysis">Analytics</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(14) ? 'block': 'none'}`}} className={selectedPage === 14 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={meta_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/creative_ideation">Creative Ideation</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(18) ? 'block': 'none'}`}} className={selectedPage === 18 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={meta_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/reports">Reports</Link>
                    </li>
                </ul>
            </li>

            <li style={{display:`${((allowed_pages_for_store.filter(item => [9,11,12,19].includes(item))).length > 0) ? 'block': 'none'}`}} className={[9,11,12,19].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'} >
                <span className='sidebar-list-hover-title' onClick={()=>{
                    let display_style = document.getElementById("sidebar-website-optimization").style.display;
                    if(display_style === "none"){
                        document.getElementById("sidebar-website-optimization-down").style.display = "none";
                        document.getElementById("sidebar-website-optimization-up").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    else{
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    
                    document.getElementById("sidebar-performance").style.display = "none";
                    document.getElementById("sidebar-integration").style.display = "none";
                    document.getElementById("sidebar-customer-behavior").style.display = "none";
                    document.getElementById("sidebar-product").style.display = "none";
                    document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
                    document.getElementById("sidebar-website-optimization").style.display = display_style === "block" ? "none": "block";
                }}>
                    <p style={{margin:"0"}}>Experience AI</p>
                    <svg id='sidebar-website-optimization-up' style={{display:`${[9,11,12,19].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                    </svg>
                    <svg id='sidebar-website-optimization-down' style={{display:`${[9,11,12,19].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg>
                </span>
                <ul className='sidebar-list-dropdown' id='sidebar-website-optimization'>
                    <li style={{display:`${allowed_pages_for_store.includes(12) ? 'block': 'none'}`}} className={selectedPage === 12 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/all-product-audit">All Product Audit</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(19) ? 'block': 'none'}`}} className={selectedPage === 19 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/landing-page-optimization">Keyword Intent Optimization</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(9) ? 'block': 'none'}`}} className={selectedPage === 9 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/product-cro">Product Optimization</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(11) ? 'block': 'none'}`}} className={selectedPage === 11 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/segment-bestseller">Segment Best Sellers</Link>
                    </li>
                </ul>
            </li>

            <li style={{display:'none'}} className={[].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'} >
                <span className='sidebar-list-hover-title' onClick={()=>{
                    let display_style = document.getElementById("sidebar-ad-copy-optimization").style.display;
                    if(display_style === "none"){
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "block";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    else{
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    
                    document.getElementById("sidebar-performance").style.display = "none";
                    document.getElementById("sidebar-integration").style.display = "none";
                    document.getElementById("sidebar-customer-behavior").style.display = "none";
                    document.getElementById("sidebar-product").style.display = "none";
                    document.getElementById("sidebar-website-optimization").style.display = "none";
                    document.getElementById("sidebar-ad-copy-optimization").style.display = display_style === "block" ? "none": "block";
                }}>
                    <p style={{margin:"0"}}>Meta Ads</p>
                    <svg id='sidebar-ad-copy-optimization-up' style={{display:`${[14,16,18,19,20].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                    </svg>
                    <svg id='sidebar-ad-copy-optimization-down' style={{display:`${[14,16,18,19,20].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg>
                </span>
                <ul className='sidebar-list-dropdown' id='sidebar-ad-copy-optimization'>

                </ul>
            </li>


            <li style={{display:`${((allowed_pages_for_store.filter(item => [5,2,7,13,0,1,3,6,8].includes(item))).length > 0) ? "block" : "none"}`}} className={[5,2,7,13,0,1,3,6,8].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'}>
                <span className='sidebar-list-hover-title' onClick={()=>{
                    let display_style = document.getElementById("sidebar-customer-behavior").style.display;
                    if(display_style === "none"){
                        document.getElementById("sidebar-customer-behavior-down").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "block";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    else{
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                    }
                    
                    document.getElementById("sidebar-performance").style.display = "none";
                    document.getElementById("sidebar-integration").style.display = "none";
                    document.getElementById("sidebar-product").style.display = "none";
                    document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
                    document.getElementById("sidebar-website-optimization").style.display = "none";
                    document.getElementById("sidebar-customer-behavior").style.display = display_style === "block" ? "none": "block";
                }}>
                    <p style={{margin:"0"}}>LTV AI</p>
                    <svg id='sidebar-customer-behavior-up' style={{display:`${[5,2,7,13,0,1,3,6,8].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                    </svg>
                    <svg id='sidebar-customer-behavior-down' style={{display:`${[5,2,7,13,0,1,3,6,8].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg>
                </span>
                <ul className='sidebar-list-dropdown' id='sidebar-customer-behavior'>
                    <li style={{display:`${allowed_pages_for_store.includes(2) ? 'block': 'none'}`}} className={selectedPage === 2 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/lifetime-value">Lifetime Value</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(5) ? 'block': 'none'}`}} className={selectedPage === 5 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/enrich-and-bid">Enrich & Bid</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(7) ? 'block': 'none'}`}} className={selectedPage === 7 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/feed-enrichment">Feed Enrichment</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(13) ? 'block': 'none'}`}} className={selectedPage === 13 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <img src={google_logo} alt='Meta' width={15} height={15} style={{marginRight:"5px"}} /><Link to="/shopping">Feed Optimization</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(0) ? 'block': 'none'}`}} className={selectedPage === 0 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/metrics-performance">Metrics Trend</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(1) ? 'block': 'none'}`}} className={selectedPage === 1 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/metrics-deepdive">Metrics Deepdive</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(3) ? 'block': 'none'}`}} className={selectedPage === 3 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/product-journey">Product Journey</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(6) ? 'block': 'none'}`}} className={selectedPage === 6 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/product-performance">Performance By Channels</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(8) ? 'block': 'none'}`}} className={selectedPage === 8 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/repurchase-rate">Repurchase Rate</Link>
                    </li>
                </ul>
            </li>

            <li style={{display:"none"}} className={[].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'}>
                <span className='sidebar-list-hover-title' onClick={()=>{
                    let display_style = document.getElementById("sidebar-performance").style.display;
                    if(display_style === "none"){
                        document.getElementById("sidebar-performance-down").style.display = "none";
                        document.getElementById("sidebar-performance-up").style.display = "block";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                    }
                    else{
                        document.getElementById("sidebar-performance-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                    }
                    
                    document.getElementById("sidebar-customer-behavior").style.display = "none";
                    document.getElementById("sidebar-integration").style.display = "none";
                    document.getElementById("sidebar-website-optimization").style.display = "none";
                    document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
                    document.getElementById("sidebar-product").style.display = "none";
                    document.getElementById("sidebar-performance").style.display = display_style === "block" ? "none": "block";
                }}>
                    <p style={{margin:"0"}}>Advanced Channel Performance</p>
                    <svg id='sidebar-performance-up' style={{display:`${[0,1,5].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                    </svg>
                    <svg id='sidebar-performance-down' style={{display:`${[0,1,5].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg>
                </span>
                <ul className='sidebar-list-dropdown' id='sidebar-performance'>

                </ul>
            </li>


            <li style={{display:`${((allowed_pages_for_store.filter(item => [4,17,21,22].includes(item))).length > 0) ? "block" : "none"}`}} className={[4,17,21,22].includes(selectedPage) ?'sidebar-list-item show-list-item-dropdown':'sidebar-list-item'}>
                <span className='sidebar-list-hover-title' onClick={()=>{
                    let display_style = document.getElementById("sidebar-integration").style.display;
                    if(display_style === "none"){
                        document.getElementById("sidebar-integration-down").style.display = "none";
                        document.getElementById("sidebar-integration-up").style.display = "block";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                    }
                    else{
                        document.getElementById("sidebar-integration-down").style.display = "block";
                        document.getElementById("sidebar-integration-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-up").style.display = "none";
                        document.getElementById("sidebar-website-optimization-down").style.display = "block";
                        document.getElementById("sidebar-ad-copy-optimization-up").style.display = "none";
                        document.getElementById("sidebar-ad-copy-optimization-down").style.display = "block";
                        document.getElementById("sidebar-product-up").style.display = "none";
                        document.getElementById("sidebar-product-down").style.display = "block";
                        document.getElementById("sidebar-performance-up").style.display = "none";
                        document.getElementById("sidebar-customer-behavior-up").style.display = "none";
                        document.getElementById("sidebar-performance-down").style.display = "block";
                        document.getElementById("sidebar-customer-behavior-down").style.display = "block";
                    }
                    document.getElementById("sidebar-performance").style.display = "none";
                    document.getElementById("sidebar-customer-behavior").style.display = "none";
                    document.getElementById("sidebar-product").style.display = "none";
                    document.getElementById("sidebar-website-optimization").style.display = "none";
                    document.getElementById("sidebar-ad-copy-optimization").style.display = "none";
                    document.getElementById("sidebar-integration").style.display = display_style === "block" ? "none": "block";
                }}>
                    <p style={{margin:"0"}}>Settings</p>
                    <svg id='sidebar-integration-up' style={{display:`${[4,17,21].includes(selectedPage) ? "block" : "none"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                    </svg>
                    <svg id='sidebar-integration-down' style={{display:`${[4,17,21].includes(selectedPage) ? "none" : "block"}`}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg>
                </span>
                <ul className='sidebar-list-dropdown' id='sidebar-integration'>
                    <li style={{display:`${allowed_pages_for_store.includes(4) ? 'block': 'none'}`}} className={selectedPage === 4 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/">Google & Meta Inegration</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(21) ? 'block': 'none'}`}} className={selectedPage === 21 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/groups">Groups</Link>
                    </li> 
                    <li style={{display:`${allowed_pages_for_store.includes(22) ? 'block': 'none'}`}} className={selectedPage === 22 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/saving-calculator">Saving Calculator</Link>
                    </li>
                    <li style={{display:`${allowed_pages_for_store.includes(17) ? 'block': 'none'}`}} className={selectedPage === 17 ?'sidebar-list-item sidebar-page-active':'sidebar-list-item'}>
                        <Link to="/meta-creative-settings">Meta Creative Settings</Link>
                    </li>
                    <li className='sidebar-list-item'>
                        <Link to="/sign-out">Sign-Out</Link>
                    </li>
                </ul>
            </li>
        </ul>
    </aside>
  )
}

export default Sidebar 